import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { FeaturesSlider } from '@latitude/features-slider';
import { Box } from '@latitude/box';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import { StickyCta } from '@latitude/sticky-cta';
import { Heading4,Heading5 } from '@latitude/heading';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { ImportantInformation } from '@latitude/important-information';
import {
  BREAKPOINT,
  COLOR,
  ALIGN,
  BUTTON_STYLE
} from '@latitude/core/utils/constants';
import PageData from '../../data/pages/become-a-partner.json';
import HeroBranded from '../../components/HeroBranded/HeroBranded';
import Layout from '../../components/layout';
import PartneringBenifits from './_partnering-benefits';
import { ALIGN_ITEMS, MARGIN } from '../../utils/constants';
import FeaturesStaggeredSlider from '../../components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';
import { Accordion } from '@latitude/accordion';
import { PromoSection } from '../../components/Promo/Promo.list';
import { Promo } from '@latitude/promo';
import videoCoverImage from '../../images/intrest-free-payment.webp';
import BrandedCallout from '@latitude/callout/BrandedCallout';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import calloutLeftBg from './images/confetti-left-desktop.svg';
import calloutRightBg from './images/confetti-right-desktop.svg';
import calloutMobileBg from './images/confetti-mobile.svg';
import { PageContext } from '../../context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';


const BusinessIFPage = props => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = state?.featureSliderData || [
    {
      heading: "The home of Interest Free shopping?",
      description: "Why Gem Interest Free options are a real head turner.",
      featureCards: PageData.whyChooseLatitude
    }
  ];
  /** - END - */

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer" css="background-color: #f8f8f8;">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/credit-cards/become-a-partner/"
          />
          <title> Become an interest free partner | Gem by Latitude</title>
          <meta
            name="description"
            content="We want to be your partners in money. Helping your business grow and creating the best shopping experience for your customers."
          />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
        <div css="position:relative;background: linear-gradient(128.6deg, #0146AA 0%, #0162ED 100%);">
          <HeroBranded
            transparentBg
            css={`
              background: linear-gradient(128.6deg, #0146aa 0%, #0162ed 100%);
              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 0;
              }
              @media (max-width: ${BREAKPOINT.SM}) {
                > div {
                  max-width: 390px;
                }
              }
              @media (min-width: ${BREAKPOINT.MD}) {
                > div {
                  > div:first-child {
                    .container {
                      padding-left: 0;
                      padding-right: 0;
                    }
                  }
                }
              }
              @media (max-width: ${BREAKPOINT.MD}) {
                > div {
                  > div:first-child {
                    margin: 0 auto;
                    padding-right: 15px;
                    height: 255px;
                  }
                  .HeroContent {
                    padding: 0 15px 40px;
                    margin: 0 auto;
                  }
                }
              }
            `}
            imageContent={
              <div
                css={`
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  img{
                    width:100%;
                  }
                  .PromoFrameContainer{
                    width: 100% !important;
                  }

                  section[class*="Promolist__PromoSection"] {
                    margin-top:20px;
                  }

                  div[class*='Promo__PromoFrameContainer'] {
                    width: fit-content !important;
                    height: max-content;
                    &:after{
                      top: 35px;
                      left: -30px;
                      border: 20px solid #FF94CA;
                      @media (max-width: ${BREAKPOINT.MD}){
                        top: 25px;
                        height: 176px;
                        max-width: 300px;
                      }
                    }
                    img{
                      height: 296px;
                      @media (max-width: ${BREAKPOINT.LG}){
                        height: height: max-content;
                      }
                      @media (max-width: ${BREAKPOINT.MD}){
                        height: 169px !important;
                        max-width: 300px !important;
                      }
                    }
                  }
                  @media (min-width: ${BREAKPOINT.LG}) {
                    justify-content: flex-start;
                    div[class*='Promo__PromoFrameContainer'] {
                      max-width: 514px !important;
                      min-width: fit-content !important;
                      height: 290px;
                    }
                  }

                `}
              >
                <PromoSection>
                  <Promo
                    frameImage={
                      <img
                        src={videoCoverImage}
                        alt="interest Free Hero image"
                      />
                    }
                    verticalAlignContent
                    frameAlignment="left"
                    frameBorderColor="#FF94CA"
                  />
                </PromoSection>
              </div>
            }
            title={
              <>
                <div
                  css={`
                    color: ${COLOR.WHITE};
                    @media (max-width: ${BREAKPOINT.MD}) {
                      margin-top: 20px;
                    }
                    @media (min-width: ${BREAKPOINT.LG}) {
                      display: block;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 48px;
                      line-height: 56px;
                    }
                  `}
                >
                  Get interested in Interest Free
                </div>
              </>
            }
            text={
              <>
                <span
                  css={`
                    color: ${COLOR.WHITE};
                  `}
                >
                  Give your customers more time to pay with long-term Interest
                  Free payments plans available through participating Gem credit
                  cards.
                </span>
              </>
            }
            buttonProps={{
              href:
                'https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=15371732506129',
              children: 'Get in touch',
              trackEventData: {
                category: 'button-link',
                action: 'external-link',
                location: 'hero'
              }
            }}
            footnote={
              <>
                <span
                  css={`
                    color: ${COLOR.WHITE};
                  `}
                >
                  <sup>^</sup>
                  All information listed on this page is intended for merchant
                  purposes only. 
                </span>
              </>
            }
          />
        </div>)}
        <div className="d-lg-none">
          <StickyCta
            triggerElem="#why-us"
            href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=15371732506129"
            trackId="get-in-touch"
            text="Get in touch"
            ctaType={BUTTON_STYLE.PRIMARY}
            isMobile
            trackEventData={{
              category: 'button-link',
              action: 'external-link',
              location: 'hero'
            }}
            trackProductId={['']}
          />
        </div>

        <div
          className="d-none d-lg-block"
          css="position: relative; z-index: 11;
          .sticky-navigation--fixed {
            position: fixed;
            }"
        >
          <StickyNavigation
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
            ctaText="Get in touch"
            ctaHref="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=15371732506129"
            trackId="sticky-navigation-get-in-touch"
            trackEventData={{
              category: 'button-link',
              action: 'external-link',
              location: 'sticky'
            }}
            css={`
              background-color: #fff;
              .sticky-navigation__cta {
                background-color: ${COLOR.GREEN} !important;
                border: 0 !important;
                &:hover {
                  background-color: ${COLOR.GREEN} !important;
                }
              }
            `}
          />
        </div>
        <div
          className="d-lg-none"
          css={`
            .sticky-cta__button {
              background-color: ${COLOR.GREEN} !important;
              border: 0 !important;
              &:hover {
                background-color: ${COLOR.GREEN} !important;
              }
            }
          `}
        >
          <StickyCta
            triggerElem="#why-us"
            href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=15371732506129"
            trackId="sticky-cta--hardship-application"
            text="Get in touch"
            trackEventData={{
              category: 'button-link',
              action: 'external-link',
              location: 'sticky'
            }}
          />
        </div>
        <Section
          id="why-us"
          css={`
        background-color: ${COLOR.WHITE};
        padding-top: 0px;
        @media (min-width: ${BREAKPOINT.LG}) {
          background-color: ${COLOR.WHITE};
        `}
        >
        {featureSliderData[0] && (
          <FeaturesSlider
            id="interest-free"
            css={`
              padding-top: 0;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 30px;
              }
              h4 {
                max-width: 815px;
                margin: 40px auto 45px;
              }
              p {
                max-width: 815px;
                margin: 0 auto;
              }
            `}
            data={featureSliderData[0].featureCards}
            className="why-choose"
            heading={featureSliderData[0].heading}
            subheading={featureSliderData[0].description}
          />
        )}
        </Section>

        <span id="how-it-works">
          <PartneringBenifits />
        </span>

        <Section
          id="partners"
          css={`
        background-color: ${COLOR.WHITE};
        @media (min-width: ${BREAKPOINT.LG}) {
          background-color: ${COLOR.WHITE};
        `}
        >
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            Our Partners
          </Heading4>

          <Box marginBottom={MARGIN.M32}>
            <p
              align={ALIGN.CENTER}
              color={COLOR.BLACK}
              css={`
                font-size: 18px;
                text-align: center;
              `}
            >
              We keep good company, so should you.{' '}
              <Link
                href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=15371732506129"
                title="Get in touch"
                target="_blank"
                trackEventData={{
                  category: 'text-link',
                  action: 'external-link',
                  location: 'our partners'
                }}
                css={`
                  text-decoration: underline;
                  text-underline-position: under;
                  text-decoration-thickness: from-font;
                  :hover {
                    border-bottom: 1px solid rgb(0, 106, 255);
                    text-shadow: rgb(0, 106, 255) 0px 0px 1px;
                  }
                `}
              >
                Get in touch{' '}
              </Link>{' '}
              today.
            </p>
          </Box>
          <Box
            padding="38px"
            isResponsive
            css={`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <div
              css={`
              display: flex;
              width: 100% !important;
              flex-wrap: wrap;
              justify-content:center;

              @media (min-width: ${BREAKPOINT.MD}) {
                width: 100% !important;
              }

              a {

                align-content: center;
                margin-right: 20px;
                margin-bottom: 20px;
                padding: 10px 40px;
                background: #fff;
                box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
                border-radius: 4px;
                align-self: flex-end;
                display: flex;
                justify-content: center;
                cursor: default;
                @media (max-width: ${BREAKPOINT.MD}) {
                  width: 100% !important;
                }

                :first-of-type {
                  margin-top: 50px;
                  @media (min-width: ${BREAKPOINT.MD}) {
                    margin-top: 0;
                  }
                }
                img {
                  align-self: center;
                  height: 26px;
                }
              }

              span {
                margin-right: 20px;
                @media (max-width: ${BREAKPOINT.MD}) {
                  width: 100% !important;

                }

                :first-of-type {
                  @media (min-width: ${BREAKPOINT.MD}) {
                    margin-top: 0;
                  }
                }

                        }
              }
            `}
            >
              <Link noStyle>
                <img
                  src={require('../../images/interest-free-merchant/apple.png')}
                  alt="Apple"
                />
              </Link>
              <Link noStyle>
                <img
                  src={require('../../images/interest-free-merchant/freedom.svg')}
                  alt="freedom"
                  css={`
                    max-width: 80px;
                  `}
                />
              </Link>
              <Link noStyle>
                <img
                  src={require('../../images/interest-free-merchant/partners-michael-hill.svg')}
                  alt="Micheal Hill"
                  css={`
                    max-width: 118px;
                  `}
                />
              </Link>
              <Link noStyle>
                <img
                  src={require('../../images/interest-free-merchant/jb-hifi.svg')}
                  alt="JB Hifi"
                />
              </Link>
              <Link noStyle>
                <img
                  src={require('../../images/interest-free-merchant/big-save-logo.svg')}
                  alt="Big Save"
                />
              </Link>
              <Link noStyle>
                <img
                  src={require('../../images/interest-free-merchant/pbtech_logo.png')}
                  alt="pb tech"
                />
              </Link>

              <Link noStyle>
                <img
                  src={require('../../images/interest-free-merchant/house-of-travel.svg')}
                  alt="House of travel"
                />
              </Link>

              <Link noStyle>
                <img
                  src={require('../../images/interest-free-merchant/neol-leeming.webp')}
                  alt="BedRUs"
                />
              </Link>
              <Link noStyle>
                <img
                  src={require('../../images/interest-free-merchant/mitre-logo.webp')}
                  alt="Mitre"
                />
              </Link>
            </div>
          </Box>
          <Box
            marginBottom={MARGIN.M32}
            css={`
              text-align: center;
            `}
          >
            <Link
              href="https://www.gemfinance.co.nz/credit-cards/where-to-shop/"
              trackEventData={{
                category: 'text-link',
                action: 'external-link',
                location: 'Our partners'
              }}
            >
              Check out the full list
            </Link>
          </Box>
        </Section>

        <span id="why-gem">
          <FeaturesStaggeredSlider
            css="background-color:#fffff;"
            data={PageData.features}
            className="whats-important"
            heading="Why Gem?"
            responsiveSettings={PageData.categoriesResponsiveSettings}
          />
        </span>
        <div
          css={`
            div + div {
              padding-bottom: 0 !important;
            }
            div[class*='Callout__CalloutInner'] h3,
            div[class*='Callout__CalloutInner'] p {
              color: #fff !important;
            }
            .callout {
              background-color: #0555c8;
              background-image: url(${calloutLeftBg}), url(${calloutRightBg});
              background-position: left top, right 122%;
              background-repeat: no-repeat, no-repeat;
              height: 310px;
              display: flex;
              align-items: center;
              @media (max-width: ${BREAKPOINT.MD}) {
                height: 383px;
                background-image: url(${calloutMobileBg});
                background-position: bottom center;
                align-items: flex-start;
              }
            }
          `}
        >
          <BrandedCallout
            confettiBackground="get-in-touch-callout.webp"
            hasConfettiBackground
            heading="Get in touch"
            line1="Send us an enquiry via the get in touch button and"
            line2="we’ll respond within three business days."
            cta={
              <Link
                trackId="get-in-touch"
                href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=15371732506129"
                variant="secondary"
                css="margin-top:16px;"
                button={BUTTON_STYLE.PRIMARY}
                trackEventData={{
                  category: 'button-link',
                  action: 'external-link',
                  location: 'get in touch'
                }}
              >
                Get in touch
              </Link>
            }
          />
        </div>

        <Box.Section
          id="faq"
          css={`
        background-color: ${COLOR.WHITE};
        @media (min-width: ${BREAKPOINT.LG}) {
          background-color: ${COLOR.WHITE};
          width:50%;
        `}
        >
          <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
            Frequently Asked Questions
          </Heading4>
          <AnalyticsLocationProvider location="frequently asked questions">
            <Accordion
              titleBgColor="grey-light"
              contentBgColor="grey-light"
              borderedChildren
              items={PageData.faq}
              style={{ padding: 16 }}
            />
          </AnalyticsLocationProvider>
        </Box.Section>

        <Box.Section
          css={`
        background-color: ${COLOR.WHITE};
        @media (min-width: ${BREAKPOINT.LG}) {
          background-color: ${COLOR.WHITE};
        `}
        >
          <Heading5 align={ALIGN.CENTER} color={COLOR.BLACK}>
            Important information
          </Heading5>

          <Text align={ALIGN.CENTER}>
            *Source: Lewers research, My2Cents Latitude online research AU
            community and external sample, February 2022.
          </Text>
        </Box.Section>
      </main>
    </Layout>
  );
}

export default BusinessIFPage;
